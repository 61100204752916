import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  layout: string;

  constructor(
    private _activatedRoute: ActivatedRoute,) {
  }

  ngOnInit(): void {
    const route = this._activatedRoute;
    this.layout = route.snapshot.data.layout
  }

}
