import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  user;

  constructor(
    private _router: Router,
    private _angularFireAuth: AngularFireAuth
  ) { }

  ngOnInit(): void {
    this._angularFireAuth.currentUser.then(user => this.user = user);
  }

  logout() {
    this._angularFireAuth.signOut().then(() => {
      this._router.navigate(['/sign-in']);
      this._angularFireAuth.currentUser.then(user => console.log(user));
    });

  }

}
