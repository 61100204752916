// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // Testing app
  // baseURL: 'https://us-central1-live-mosque-test.cloudfunctions.net/',

  // Live app
  baseURL: 'https://us-central1-azan-994ad.cloudfunctions.net/',
  // SQLBaseURL: 'http://localhost:3000/',
  SQLBaseURL: 'https://api.mosquelist.archers.tech/',

  firebase: {
    apiKey: "AIzaSyC6lJzx2dv7UaPIRWYHqtlfW_V0DFkKVtU",
    authDomain: "azan-994ad.firebaseapp.com",
    databaseURL: "https://azan-994ad.firebaseio.com",
    projectId: "azan-994ad",
    storageBucket: "azan-994ad.appspot.com",
    messagingSenderId: "41224040890",
    appId: "1:41224040890:web:d36d29f6f9f63551112d83",
    measurementId: "G-FTRWZQTEMH"

    // apiKey: "AIzaSyCaJz0CCyS_hBp56sLWDyxwpDfoXIPCCU4",
    // authDomain: "live-mosque-test.firebaseapp.com",
    // databaseURL: "https://live-mosque-test-default-rtdb.firebaseio.com",
    // projectId: "live-mosque-test",
    // storageBucket: "live-mosque-test.appspot.com",
    // messagingSenderId: "681943942349",
    // appId: "1:681943942349:web:682acfdf77ae2d7b67d584",
    // measurementId: "G-S0CBX0C08C"

  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
